import { Redirect } from 'react-router-dom';
import { defaultStylingSchema } from './components/Blocks/schema';
import { ButtonStylingSchema } from './components/Blocks/Button/schema';
import { imageBlockSchemaEnhancer } from './components/Blocks/Image/schema';
import { videoBlockSchemaEnhancer } from './components/Blocks/Video/schema';
import { socialBlockSchemaEnhancer } from './components/Blocks/SocialBlocks/schema';
import {
  teaserSchemaEnhancer,
  gridTeaserSchemaEnhancer,
  standaloneTeaserSchemaEnhancer,
} from './components/Blocks/Teaser/schema';
import { contactListSchemaEnhancer } from './components/Blocks/ContactList/schema';
import ExtraAlignWrapper from './components/Blocks/Slate/ExtraAlignWrapper';
import ContactListView from './components/Blocks/ContactList/View';
import ContactListEdit from './components/Blocks/ContactList/Edit';
import { ContactListDataAdapter } from './components/Blocks/ContactList/adapter';
import IntroductionBlockView from './components/Blocks/Introduction/View';
import IntroductionBlockEdit from './components/Blocks/Introduction/Edit';
import AnchorBlockView from './components/Blocks/Anchor/View';
import AnchorBlockEdit from './components/Blocks/Anchor/Edit';
import MapBlockView from './components/Blocks/Map/View';
import MapBlockEdit from './components/Blocks/Map/Edit';
import { backspaceInList } from './components/Blocks/Introduction/keyboard';
import HighlightView from './components/Blocks/Highlight/View';
import HighlightEdit from './components/Blocks/Highlight/Edit';
import AudioBlockView from './components/Blocks/Audio/View';
import AudioBlockEdit from './components/Blocks/Audio/Edit';
import AudioBlockSchema from './components/Blocks/Audio/schema';
import AlignWidget from './components/Widgets/AlignWidget';
import AnchorWidget from './components/Widgets/AnchorWidget';
import VideoUrlWidget from './components/Widgets/VideoUrlWidget';
import TitleWidget from './components/Widgets/TitleWidget';
import DasDLRBlockEdit from './components/Blocks/DasDLR/Edit';
import DasDLRBlockView from './components/Blocks/DasDLR/View';
import { HighlightStylingSchema } from './components/Blocks/Highlight/schema';
import ImageSliderEdit from './components/Blocks/ImageSlider/Edit';
import ImageSliderView from './components/Blocks/ImageSlider/View';
import { ImageSliderDataAdapter } from './components/Blocks/ImageSlider/adapter';
import ProjectFactsheetEdit from './components/Blocks/ProjectFactsheet/Edit';
import ProjectFactsheetView from './components/Blocks/ProjectFactsheet/View';
import ProjectFactsheetDataAdapter from './components/Blocks/ProjectFactsheet/adapter';
import TopSideFacets from './components/Blocks/Search/TopSideFacets';
import { SeparatorStylingSchema } from './components/Blocks/Separator/schema';
import SubscriberForm from './components/SubscribeForm/SubscriberForm';
import reducers from './reducers';

import LinkView from '@plone/volto/components/theme/View/LinkView';
import BooleanSelectFacet from './components/Blocks/Search/BooleanSelectFacet';
import { searchBlockSchemaEnhancer } from './components/Blocks/Search/schema';
import AudioView from './components/Theme/AudioView';
import AuthorProfileView from './components/Theme/AuthorProfileView';
import TagView from './components/Theme/TagView';
import ConditionalRedirectView from './components/Theme/ConditionalRedirectView';
import ContactView from './components/Theme/ContactView';
import EventView from './components/Theme/EventView';
import PostView from './components/Theme/PostView';
import VideoView from './components/Theme/VideoView';
import LongpagerView from './components/Theme/LongpagerView';

import SimplifiedRssTemplate from './components/Blocks/RSS/SimplifiedRssTemplate';
import SliderDefaultBody from './components/Blocks/Slider/DefaultBody';
import { SliderBlockDataAdapter } from './components/Blocks/Slider/adapter';
import { sliderBlockSchemaEnhancer } from './components/Blocks/Slider/schema';

import { Search } from '@plone/volto/components';
import { SelectFacetFilterListEntry } from '@plone/volto/components/manage/Blocks/Search/components';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { copyContentForSolr, solrSearchContent } from 'volto-dlr/actions';

import { restrictedExceptForSubsites } from 'volto-dlr/helpers';

import DefaultListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/DefaultTemplate';
import ImageGalleryListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/ImageGallery';
import EventImageListingTemplate from './components/Blocks/ListingTemplate/eventListingImage';
import EventListingTemplate from './components/Blocks/ListingTemplate/eventListing';
import FileImageListingTemplate from './components/Blocks/ListingTemplate/fileListingImage';
import NewsImageListingTemplate from './components/Blocks/ListingTemplate/newsListingImage';
import NewsListingTemplate from './components/Blocks/ListingTemplate/newsListing';
import BlogListingTemplate from './components/Blocks/ListingTemplate/blogListing';
import NewsGridListingTemplate from './components/Blocks/ListingTemplate/newsGridListing';
import NewsItem from './components/Blocks/Teaser/NewsItem';
import FileItem from './components/Blocks/Teaser/FileItem';
import linkSVG from '@plone/volto/icons/link.svg';
import textIntroductionSVG from '@plone/volto/icons/subtext.svg';
import buttonSVG from 'volto-dlr/icons/block_icn_button.svg';
import listingSVG from './icons/block-listing.svg';
import summarySVG from '@plone/volto/icons/summary.svg';
import gridSVG from 'volto-dlr/icons/block_icn_grid.svg';
import presentationSVG from '@plone/volto/icons/presentation.svg';
import worldSVG from 'volto-dlr/icons/world.svg';
import infoSVG from '@plone/volto/icons/info.svg';
import serverSVG from 'volto-dlr/icons/server.svg';
import collectionSVG from 'volto-dlr/icons/collection.svg';
import separatorSVG from 'volto-dlr/icons/separator.svg';
import dasDLRBlockSVG from 'volto-dlr/icons/block-icon-das-dlr.svg';
import { defineMessages } from 'react-intl';
import contentSVG from '@plone/volto/icons/content.svg';
import enterpriseSVG from '@plone/volto/icons/enterprise.svg';
import groupSVG from '@plone/volto/icons/group.svg';
import imagesSVG from '@plone/volto/icons/images.svg';
import mapsSVG from '@plone/volto/icons/map.svg';
import videoSVG from '@plone/volto/icons/videocamera.svg';
import audioSVG from '@plone/volto/icons/audio.svg';
import applicationSVG from '@plone/volto/icons/application.svg';
import postSVG from '@plone/volto/icons/content-existing.svg';
import userSVG from '@plone/volto/icons/user.svg';
import showcaseSVG from '@plone/volto/icons/showcase.svg';
import SummaryListingBlockTemplate from 'volto-dlr/components/Blocks/ListingTemplate/SummaryTemplate';
import TableEdit from 'volto-dlr/components/Blocks/Table/Edit.jsx';
import TableView from 'volto-dlr/components/Blocks/Table/View.jsx';
import ContactForm from 'volto-dlr/components/ContactForm/ContactForm';
import SubsiteContactForm from 'volto-dlr/components/ContactForm/SubsiteContactForm';
import SchoolLabContactForm from 'volto-dlr/components/ContactForm/SchoollabContactForm';
import RssView from 'volto-dlr/components/RSS/Rss';
import htmlSVG from 'volto-dlr/icons/block_icn_html.svg';
import tocSVG from 'volto-dlr/icons/block_icn_inhaltsverzeichnis.svg';
import mapSVG from 'volto-dlr/icons/block_icn_maps.svg';
import tableSVG from 'volto-dlr/icons/block_icn_tabelle.svg';
import textSVG from 'volto-dlr/icons/block_icn_text.svg';

import { Img } from '@kitconcept/volto-blocks/components';
import defaultImage from 'volto-dlr/static/default-16-9.svg';

import * as searchResultItems from 'volto-dlr/customizations/volto/components/theme/Search/resultItems';
import { listingSchemaEnhancer } from './components/Blocks/Listing/schema';

import { Banner } from '@kitconcept/volto-dsgvo-banner/components';
import { composeSchema } from '@plone/volto/helpers';
import StagingBanner from 'volto-dlr/components/StagingBanner/StagingBanner';
import ContactFormCategoriesWidget from 'volto-dlr/components/Widgets/ContactFormCategoriesWidget';
import JSONListWidget from 'volto-dlr/components/Widgets/JSONListWidget';
import EditorEmails from './components/Reports/EditorEmails';
import VideoAdd from './components/Video/Add';
import TranslationNotFound from './components/TranslationNotFound/TranslationNotFound';
import { externalRoutes } from './externalRoutes';

import InfoboxView from './components/Blocks/Infobox/View';
import InfoboxEdit from './components/Blocks/Infobox/Edit';
import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';
import descriptionSVG from '@plone/volto/icons/description.svg';
import EventMetadataView from './components/Blocks/EventMetadata/View';

import IframeEdit from './components/Blocks/Iframe/Edit';
import iframeView from './components/Blocks/Iframe/View';

import BannerBlockEdit from './components/Blocks/Banner/Edit';
import { BannerBlockSchema } from './components/Blocks/Banner/schema';
import BannerBlockView from './components/Blocks/Banner/View';

import NewsletterSubscribeForm from './components/Newsletter/SubscribeForm';
import NewsletterConfirmationForm from './components/Newsletter/ConfirmForm';
import NewsletterUnsubscribeForm from './components/Newsletter/UnsubscribeForm';
import NewsletterDelivery from './components/Newsletter/Delivery';
import contentsSchemaEnhancer from './components/Contents/schema';

const DLR_BG_COLORS = [
  { name: 'transparent', label: 'Transparent' },
  { name: 'grey', label: 'Grey' },
];

const DLR_CONTENT_COLORS = [
  { name: 'blue3', label: 'Light Blue' },
  { name: 'orange3', label: 'Light Orange' },
  { name: 'green3', label: 'Light Green' },
  { name: 'blue5', label: 'Blue' },
  { name: 'green5', label: 'Green' },
];

defineMessages({
  by: {
    id: 'by',
    defaultMessage: 'by',
  },
  isValidURL: {
    id: 'url',
    defaultMessage:
      'Input must be valid url (www.something.com or http(s)://www.something.com)',
  },
  ButtonText: {
    id: 'Continue reading',
    defaultMessage: 'Continue reading',
  },
  introduction: {
    id: 'Introduction',
    defaultMessage: 'Introduction',
  },
  anchor: {
    id: 'Anchor',
    defaultMessage: 'Anchor',
  },
  map: {
    id: 'map',
    defaultMessage: 'Map Diagram',
  },
  maps: {
    id: 'maps',
    defaultMessage: 'Map Service',
  },
  Maps: {
    id: 'Maps',
    defaultMessage: 'Map Service',
  },
  image: {
    id: 'Image: ',
    defaultMessage: 'Image: ',
  },
  HeaderAccessibility: {
    id: 'HeaderAccessibility',
    defaultMessage: 'Accessibility',
  },
  Press: {
    id: 'Press',
    defaultMessage: 'Press',
  },
  Contacts: {
    id: 'Contacts',
    defaultMessage: 'Contacts',
  },
  Of: {
    id: 'of',
    defaultMessage: 'of',
  },
  Result: {
    id: 'Result',
    defaultMessage: 'Result',
  },
  summaryListing: {
    id: 'Standard with image',
    defaultMessage: 'Standard with image',
  },
  eventImageListing: {
    id: 'Events with image',
    defaultMessage: 'Events with image',
  },
  imageGallery: {
    id: 'Image slider',
    defaultMessage: 'Image slider',
  },
  nextPage: {
    id: 'Next Page',
    defaultMessage: 'Next Page',
  },
  previousPage: {
    id: 'Previous Page',
    defaultMessage: 'Previous page',
  },
  noItemsFoundInContainer: {
    id: 'No items found in this container.',
    defaultMessage: 'No items found in this container.',
  },
  noResultsFound: {
    id: 'No results found.',
    defaultMessage: 'No results found.',
  },
  technicalMalfunction: {
    id: 'Technical malfunction',
    defaultMessage: 'Technical malfunction',
  },
  dueToATechnicalMalfunction: {
    id:
      '<p>Due to a technical malfunction this site is currently unavailable. There can be many causes, like e.g. disrupted network connection.</p><p>Please check your internet connection and reload the page.</p>',
    defaultMessage:
      '<p>Due to a technical malfunction this site is currently unavailable. There can be many causes, like e.g. disrupted network connection.</p><p>Please check your internet connection and reload the page.</p>',
  },
  overview: {
    id: 'Overview',
    defaultMessage: 'Overview',
  },
  limit: {
    id: 'Results limit',
    defaultMessage: 'Max. number of list items',
  },
  itemBatchSize: {
    id: 'Item batch size',
    defaultMessage: 'Pagination: items per page',
  },
  pending: {
    id: 'pending',
    defaultMessage: 'Pending',
  },
  videoConsentText: {
    id:
      "Your consent to the storage of data ('cookies') is required for the playback of this video on {website}. You can view and change your current data storage settings at any time under {privacy}.",
    defaultMessage:
      "Your consent to the storage of data ('cookies') is required for the playback of this video on {website}. You can view and change your current data storage settings at any time under {privacy}.",
  },
  storageSettings: {
    id: 'data storage settings',
    defaultMessage: 'data storage settings',
  },
  privacy: {
    id: 'privacy',
    defaultMessage: 'privacy',
  },
  agreeVideo: {
    id: 'Agree and show video',
    defaultMessage: 'Agree and show video',
  },
  technically_required: {
    id: 'CMS Plone (provided by DLR e.V)',
    defaultMessage: 'CMS Plone (provided by DLR e.V)',
  },
  headline_technically_required: {
    id:
      'Data storage that is technically mandatory for the operation of the website (cannot be deselected).',
    defaultMessage:
      'Data storage that is technically mandatory for the operation of the website (cannot be deselected).',
  },
  video_player: {
    id: 'Video player',
    defaultMessage: 'Video player',
  },
  add: {
    id: 'Add (object list)',
    defaultMessage: 'Add',
  },
  booleanSelectFacet: {
    id: 'booleanSelectFacet',
    defaultMessage: 'Boolean Select',
  },
  toggleFacet: {
    id: 'toggleFacet',
    defaultMessage: 'Toggle',
  },
  selectFacet: {
    id: 'selectFacet',
    defaultMessage: 'Select',
  },
  documents: {
    id: 'Documents',
    defaultMessage: 'Documents',
  },
  images: {
    id: 'Images',
    defaultMessage: 'Images',
  },
  viewRevision: {
    id: 'View this revision',
    defaultMessage: 'View this revision',
  },
  externalServices: {
    id: 'External services',
    defaultMessage: 'External services',
  },
  totalResultsFor: {
    id: '{total, plural, =0 {No results} =1 {# result} other {# results}} for',
    defaultMessage:
      '{total, plural, =0 {No results} =1 {# result} other {# results}} for',
  },
  totalResults: {
    id: '{total, plural, =0 {No results} =1 {# result} other {# results}}',
    defaultMessage:
      '{total, plural, =0 {No results} =1 {# result} other {# results}}',
  },
  clear: {
    id: 'Clear',
    defaultMessage: 'Clear',
  },
  closeSearch: {
    id: 'Close search',
    defaultMessage: 'Close search',
  },
  NewsArchiveIntro: {
    id: 'NewsArchiveIntro',
    defaultMessage:
      'This article has been archived. To read or download the article (PDF) please click on the following link.',
  },
  FileImageListing: {
    id: 'File with image',
    defaultMessage: 'File with image',
  },
  NewsGridListing: {
    id: 'News as Grid',
    defaultMessage: 'News as Grid',
  },
  helperTextMap: {
    id:
      "Please enter HTML map embed code provided by Google / Open Street Map: The code should contain an '<iframe >'-element.",
    defaultMessage:
      "Please enter HTML map embed code provided by Google / Open Street Map: The code should contain an '<iframe >'-element.",
  },
  externalVideoAudioServices: {
    id: 'External video / audio services',
    defaultMessage: 'External video / audio services',
  },
  externalMapServices: {
    id: 'External map services',
    defaultMessage: 'External map services',
  },
  externalSocialMediaServices: {
    id: 'External social media services',
    defaultMessage: '"External social media services"',
  },
  FurtherPages: {
    // Sonstige Seite
    id: 'Further Pages',
    defaultMessage: 'Further Pages',
  },
  NewsItem: {
    // Nachricht
    id: 'News Item',
    defaultMessage: 'News Item',
  },
  Event: {
    // Termin
    id: 'Event',
    defaultMessage: 'Event',
  },
  ProjectMission: {
    // Projekt / Mission
    id: 'Project / Mission',
    defaultMessage: 'Project / Mission',
  },
  ResearchTopic: {
    // Thema
    id: 'Research Topic',
    defaultMessage: 'Featured Topic',
  },
  ResearchInfrastructure: {
    // Forschungsinfrastruktur
    id: 'Research Infrastructure',
    defaultMessage: 'Research Infrastructure',
  },
  Location: {
    // Standort
    id: 'Location',
    defaultMessage: 'Location',
  },
  Video: {
    // Video
    id: 'Video',
    defaultMessage: 'Video',
  },
  Audio: {
    // Audio
    id: 'Audio',
    defaultMessage: 'Audio',
  },
  Image: {
    // Bild
    id: 'Image',
    defaultMessage: 'Image',
  },
  Download: {
    // Download
    id: 'Download',
    defaultMessage: 'Download',
  },

  newsImageListing: {
    id: 'News with image',
    defaultMessage: 'News with image',
  },
  eventListing: {
    id: 'Events',
    defaultMessage: 'Events',
  },
  newsListing: {
    id: 'News',
    defaultMessage: 'News',
  },
  allImages: {
    id: 'Show / search all images (global)',
    defaultMessage: 'Show / search all images (global)',
  },
  errorPage: {
    id: '404 Error page',
    defaultMessage: '404 Error page',
  },
  errorTitle: {
    id: 'Error 404: What does this error message mean?',
    defaultMessage: 'Error 404: What does this error message mean?',
  },
  errorReasons: {
    id: 'There can be various reasons why content cannot be found:',
    defaultMessage: 'There can be various reasons why content cannot be found:',
  },
  errorReasonOne: {
    id:
      'The content has been moved or deleted (the latter occurs very rarely).',
    defaultMessage:
      'The content has been moved or deleted (the latter occurs very rarely).',
  },
  errorReasonTwo: {
    id:
      'The address you entered or the link you clicked is incorrect (e.g. a typo).',
    defaultMessage:
      'The address you entered or the link you clicked is incorrect (e.g. a typo).',
  },
  errorSearch: {
    id: 'We recommend using the search of our web portal DLR.de.',
    defaultMessage: 'We recommend using the search of our web portal DLR.de.',
  },
  searchSite: {
    id: 'Search the website DLR.de',
    defaultMessage: 'Search the website DLR.de',
  },
  newsItems: {
    id: 'News Items',
    defaultMessage: 'News Items',
  },
  allNewsItems: {
    id: 'All News Items',
    defaultMessage: 'All News Items',
  },
  TypeSearchWords: {
    id: 'Search...',
    defaultMessage: 'Search...',
  },
  AeronauticsTitle: {
    id: 'Attention:',
    defaultMessage: 'Attention:',
  },
  AeronauticsDescription: {
    id: "Passengers travelling to 'DLR' will be transferred to another page.",
    defaultMessage:
      "Passengers travelling to 'DLR' will be transferred to another page.",
  },
  DigitalisationTitle: {
    id: 'Attention:',
    defaultMessage: 'Attention:',
  },
  DigitalisationDescription: {
    id: '01000001 01100011 01101000 01110100 01110101 01101110 01100111',
    defaultMessage:
      '01000001 01100011 01101000 01110100 01110101 01101110 01100111',
  },
  EnergyTitle: {
    id: 'Power outage!',
    defaultMessage: 'Power outage!',
  },
  EnergyDescription: {
    id: 'Unfortunately, the URL you have selected does not exist.',
    defaultMessage: 'Unfortunately, the URL you have selected does not exist.',
  },
  TrafficTitle: {
    id: 'Please go back:',
    defaultMessage: 'Please go back:',
  },
  TrafficDescription: {
    id: "You can't go any further ...",
    defaultMessage: "You can't go any further ...",
  },
  SpaceTitle: {
    id: 'Congratulations!',
    defaultMessage: 'Congratulations!',
  },
  SpaceDescription: {
    id: 'You have discovered a black hole!',
    defaultMessage: 'You have discovered a black hole!',
  },
  SecurityTitle: {
    id: 'Attention:',
    defaultMessage: 'Attention:',
  },
  SecurityDescription: {
    id: 'Sorry, you cannot access this page',
    defaultMessage: 'Sorry, you cannot access this page',
  },
  URLDoesNotExist: {
    id: 'Unfortunately, this URL does not exist.',
    defaultMessage: 'Unfortunately, this URL does not exist.',
  },
  searchResults: {
    id: 'Search results',
    defaultMessage: 'Search results',
  },
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
  scheduled: {
    id: 'Scheduled',
    defaultMessage: 'Scheduled',
  },
  searchLocalizedLabel: {
    // Suchergebnisse eingrenzen:
    id: 'searchLocalizedLabel',
    defaultMessage: 'Narrow down search results:',
  },
  searchGlobalized: {
    // Alle Webauftritte auf DLR.de (keine Eingrenzung)
    id: 'searchGlobalized',
    defaultMessage: 'All websites on DLR.de (no narrowing)',
  },
  searchLocalized: {
    // Nur diese Website durchsuchen
    id: 'searchLocalized',
    defaultMessage: 'Only search this website',
  },
  searchSubsiteLocalized: {
    // Nur das DLR Hauptportal durchsuchen
    id: 'searchSubsiteLocalized',
    defaultMessage: 'Search only DLR main portal',
  },
  heading_sent_password: {
    id: 'heading_sent_password',
    defaultMessage:
      'An email containing a link for setting a new password has been sent to you',
  },
  dlr_description_sent_password1: {
    id: 'dlr_description_sent_password1',
    defaultMessage:
      'Within a few minutes you should find an email in your inbox with subject “CMS Plone: set a new password”.',
  },
  dlr_description_sent_password2: {
    id: 'dlr_description_sent_password2',
    defaultMessage:
      'By using the link contained in this email you can set a new password for accessing CMS Plone.',
  },
  dlr_description_sent_password3: {
    id: 'dlr_description_sent_password3',
    defaultMessage:
      'If you have not received an email within 15 minutes, please request again a {link_to_password_reset_page}.',
  },
  NotLoggedIn: {
    id: 'You are not logged in.',
    defaultMessage: 'You are not logged in.',
  },
  unauthorizedParagraph1: {
    id: 'EditorialUnauthMessage',
    defaultMessage:
      'Since you are not logged in to the editorial system, you cannot see the content either because it has not yet been published or because you have tried to execute a function that is reserved exclusively for editors.',
  },
  unauthorizedParagraph2: {
    id:
      'Please log in to the editorial system to view, edit or publish content.',
    defaultMessage:
      'Please log in to the editorial system to view, edit or publish content.',
  },
  LogInNow: {
    id: 'Log in now',
    defaultMessage: 'Log in now',
  },
  timeoutErrorTitle: {
    id: 'Timeout occurred',
    defaultMessage: 'Timeout occured',
  },
  timeoutErrorMessage: {
    id:
      'A timeout has occurred. The request likely will go through (CMS is busy). Wait 10 minutes and then please reload the page and check in the UI if the operation was successful. If the operation was not successful please contact UHD and report the problem with detailed description what action was performed on what page (providing URL)',
    defaultMessage:
      'A timeout has occurred. The request likely will go through (CMS is busy). Wait 10 minutes and then please reload the page and check in the UI if the operation was successful. If the operation was not successful please contact UHD and report the problem with detailed description what action was performed on what page (providing URL)',
  },
  portalLinkText: {
    id: 'German Aerospace Center (DLR) - Home',
    defaultMessage: 'German Aerospace Center (DLR) - Home',
  },
  IncorrectUrl: {
    id: 'Only Internal Video content type url is allowed.',
    defaultMessage: 'Only Internal Video content type url is allowed.',
  },
  IncorrectContentType: {
    id: 'Only Video content type url is allowed.',
    defaultMessage: 'Only Video content type url is allowed.',
  },
  IncorrectLink: {
    id: 'Please enter a valid link.',
    defaultMessage: 'Please enter a valid link.',
  },
  language_independent_icon_title: {
    id: 'Language independent icon title',
    defaultMessage:
      'This field is a language-independent field. Any value you enter here will be synced between the translations when you save this form.',
  },
});

const applyConfig = (config) => {
  // Initialize slots
  config.slots = {};

  config.settings.dlrSubsitesThemes = {
    default: {
      color: '#B1B1B1',
    }, // default
    energy: {
      color: '#CAD55C',
    }, // Energie
    transport: {
      color: '#628023',
      inverseFontColor: '#FFF',
    }, // Verkehr
    security: {
      color: '#F8DE53',
    }, // Sicherheit
    aviation: {
      color: '#6CB9DC',
    }, // Luftfahrt
    space_travel: {
      color: '#005F85',
      inverseFontColor: '#FFF',
    }, // Raumfahrt
    space_agency: {
      color: '#B1B1B1',
    }, // Raumfahrtagentur
    pt_lf: {
      color: '#1E7BAE',
      inverseFontColor: '#FFF',
    }, // Raumfahrtagentur
    career: {
      color: '#A7D3EC',
    }, // Karriere
    school_lab: {
      color: '#A7D3EC',
      inverseFontColor: '#FFF',
    }, // School_Lab
  };

  // No async listing block for now
  delete config.blocks.blocksConfig.listing.getAsyncData;

  const contentIcons = {
    ...config.settings.contentIcons,
    Contact: userSVG,
    Institute: enterpriseSVG,
    Location: mapsSVG,
    'Research Infrastructure': serverSVG,
    'Research Project': worldSVG,
    'Research Topic': collectionSVG,
    Subsidiary: mapsSVG,
    Video: videoSVG,
    Audio: audioSVG,
    Subsite: contentSVG,
    Post: postSVG,
    Author: userSVG,
  };

  config.settings = {
    ...config.settings,
    isMultilingual: true,
    hasWorkingCopySupport: false,
    listingPreviewImageField: 'preview_image',
    supportedLanguages: ['de', 'en'],
    defaultLanguage: 'de',
    navDepth: 3,
    contentIcons,
    querystringSearchGet: true,
    contentMetadataTagsImageField: 'preview_image_link',
    maxFileUploadSize: 26214400 * 2,
  };

  config.settings.apiExpanders = [
    ...config.settings.apiExpanders,
    {
      match: '',
      GET_CONTENT: ['breadcrumbs', 'actions', 'types'],
    },
    {
      match: '',
      GET_CONTENT: ['navigation'],
      querystring: {
        'expand.navigation.depth': config.settings.navDepth,
      },
    },
  ];

  config.settings.externalRoutes = externalRoutes;

  config.widgets.widget.inner_align = AlignWidget;
  config.widgets.widget.contactFormCategories = ContactFormCategoriesWidget;
  config.widgets.widget.json_list = JSONListWidget;
  config.widgets.widget.anchor = AnchorWidget;
  config.widgets.widget.video_url = VideoUrlWidget;
  config.widgets.id.title = TitleWidget;

  config.registerComponent({
    name: 'Teaser',
    component: NewsItem,
    dependencies: 'News Item',
  });

  config.registerComponent({
    name: 'Add',
    component: VideoAdd,
    dependencies: 'Video',
  });

  config.registerComponent({
    name: 'Teaser',
    component: FileItem,
    dependencies: 'File',
  });

  // TODO: Use it everywhere
  config.registerComponent({
    name: 'DefaultImage',
    component: defaultImage,
  });

  delete config.blocks.blocksConfig.form;

  config.blocks.blocksConfig.imagesGrid.restricted = true;
  config.blocks.blocksConfig.teaserGrid.restricted = true;
  config.blocks.blocksConfig.leadimage.restricted = true;
  config.blocks.blocksConfig.description.restricted = true;
  config.blocks.blocksConfig.html.restricted = true;

  config.blocks.requiredBlocks = [
    ...config.blocks.requiredBlocks,
    'eventMetadata',
  ];

  config.blocks.blocksConfig.slate.icon = textSVG;
  config.blocks.blocksConfig.slateTable.icon = tableSVG;
  config.blocks.blocksConfig.slateTable.group = 'text';
  config.blocks.blocksConfig.slateTable.edit = TableEdit;
  config.blocks.blocksConfig.slateTable.view = TableView;
  config.blocks.blocksConfig.toc.icon = tocSVG;
  config.blocks.blocksConfig.maps.icon = mapSVG;
  config.blocks.blocksConfig.html.icon = htmlSVG;

  config.blocks.blocksConfig.banner = {
    id: 'banner',
    title: 'Banner',
    group: 'teasers',
    icon: showcaseSVG,
    view: BannerBlockView,
    edit: BannerBlockEdit,
    blockSchema: BannerBlockSchema,
    restricted: restrictedExceptForSubsites([
      '/de/schoollab/',
      '/en/schoollab/',
      '/de/next/',
      '/en/next/',
    ]),
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.quote = {
    ...config.blocks.blocksConfig.quote,
    defaultLang: 'de',
    allowedLanguages: ['de', 'en'],
  };

  config.blocks.blocksConfig.heading = {
    ...config.blocks.blocksConfig.heading,
    colors: DLR_BG_COLORS,
    schemaEnhancer: defaultStylingSchema,
    sidebarTab: 1,
    allowed_headings: [['h2', 'h2']],
  };

  config.blocks.blocksConfig.image = {
    ...config.blocks.blocksConfig.image,
    schemaEnhancer: imageBlockSchemaEnhancer,
    colors: DLR_BG_COLORS,
  };

  config.blocks.blocksConfig.video = {
    ...config.blocks.blocksConfig.video,
    schemaEnhancer: videoBlockSchemaEnhancer,
    colors: DLR_BG_COLORS,
  };

  config.blocks.blocksConfig.audio = {
    id: 'audio',
    title: 'Audio',
    icon: audioSVG,
    group: 'media',
    view: AudioBlockView,
    edit: AudioBlockEdit,
    schema: AudioBlockSchema,
    schemaEnhancer: defaultStylingSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    colors: DLR_BG_COLORS,
  };

  config.blocks.blocksConfig.eventMetadata = {
    id: 'eventMetadata',
    title: 'Event Metadata',
    icon: descriptionSVG,
    group: 'common',
    view: EventMetadataView,
    edit: EventMetadataView,
    schema: BlockSettingsSchema,
    restricted: ({ properties, block }) =>
      properties['@type'] === 'Event' ? false : true,
    mostUsed: false,
    sidebarTab: 0,
  };

  config.blocks.blocksConfig.iframe = {
    id: 'iframe',
    title: 'Iframe',
    icon: applicationSVG,
    group: 'common',
    view: iframeView,
    edit: IframeEdit,
    schemaEnhancer: defaultStylingSchema,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    colors: DLR_BG_COLORS,
  };

  config.blocks.blocksConfig.slate = {
    ...config.blocks.blocksConfig.slate,
    colors: DLR_BG_COLORS,
    schemaEnhancer: defaultStylingSchema,
    sidebarTab: 1,
    view: ExtraAlignWrapper(config.blocks.blocksConfig.slate.view),
  };

  config.blocks.blocksConfig.highlight = {
    id: 'highlight',
    title: 'Highlight',
    icon: presentationSVG,
    group: 'teasers',
    view: HighlightView,
    edit: HighlightEdit,
    blockHasOwnFocusManagement: true,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    schemaEnhancer: HighlightStylingSchema,
    descriptionColors: DLR_CONTENT_COLORS,
  };

  config.blocks.blocksConfig.introduction = {
    id: 'introduction',
    title: 'Introduction',
    icon: textIntroductionSVG,
    group: 'text',
    view: IntroductionBlockView,
    edit: IntroductionBlockEdit,
    // This has a good reason: Slate does not work in detached mode if enabled
    blockHasOwnFocusManagement: true,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    schemaEnhancer: defaultStylingSchema,
    colors: DLR_BG_COLORS,
  };

  config.blocks.blocksConfig.anchor = {
    id: 'anchor',
    title: 'Anchor',
    icon: linkSVG,
    group: 'common',
    view: AnchorBlockView,
    edit: AnchorBlockEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.map = {
    id: 'map',
    title: 'Map Diagram',
    icon: mapSVG,
    group: 'common',
    view: MapBlockView,
    edit: MapBlockEdit,
    restricted: restrictedExceptForSubsites([
      '/de/schoollab/',
      '/en/schoollab/',
      '/de/next/',
      '/en/next/',
    ]),
    mostUsed: true,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.__button = {
    ...config.blocks.blocksConfig.__button,
    colors: DLR_BG_COLORS,
    schemaEnhancer: ButtonStylingSchema,
    icon: buttonSVG,
  };

  config.blocks.blocksConfig.slider = {
    ...config.blocks.blocksConfig.slider,
    colors: DLR_BG_COLORS,
    title: 'Teaser-Slider',
    defaultBGColor: 'transparent',
    schemaEnhancer: sliderBlockSchemaEnhancer,
    extensions: { blockSchema: { title: 'Teaser Slider' } }, // We need same title in german as well as english. https://gitlab.dlr.de/internet-cms/cms-plone/dlr-internet/-/issues/1442
    group: 'teasers',
    dataAdapter: SliderBlockDataAdapter,
    variations: [
      {
        id: 'default',
        isDefault: true,
        title: 'Default',
        view: SliderDefaultBody,
      },
    ],
  };

  config.blocks.blocksConfig.dasdlr = {
    id: 'dasdlr',
    title: 'Das DLR',
    icon: dasDLRBlockSVG,
    group: 'common',
    view: DasDLRBlockView,
    edit: DasDLRBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    enableStyling: false,
  };

  config.blocks.blocksConfig.imageslider = {
    id: 'imageslider',
    title: 'Image Slider',
    icon: imagesSVG,
    group: 'None',
    view: ImageSliderView,
    edit: ImageSliderEdit,
    dataAdapter: ImageSliderDataAdapter,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    enableStyling: false,
  };

  config.blocks.blocksConfig.separator = {
    ...config.blocks.blocksConfig.separator,
    schemaEnhancer: SeparatorStylingSchema,
    icon: separatorSVG,
    sidebarTab: 1,
    colors: DLR_BG_COLORS,
    group: 'None',
  };

  config.blocks.blocksConfig.hero = {
    ...config.blocks.blocksConfig.hero,
    restricted: true,
  };
  config.blocks.blocksConfig.description = {
    ...config.blocks.blocksConfig.description,
    restricted: true,
  };

  config.blocks.blocksConfig.teaser = {
    ...config.blocks.blocksConfig.teaser,
    icon: summarySVG,
    group: 'teasers',
    imageScale: 'larger',
    colors: DLR_BG_COLORS,
    defaultBGColor: 'transparent',
    schemaEnhancer: composeSchema(
      teaserSchemaEnhancer,
      standaloneTeaserSchemaEnhancer,
    ),
  };

  config.blocks.blocksConfig.contactList = {
    id: 'contactList',
    title: 'Contacts',
    icon: groupSVG,
    view: ContactListView,
    edit: ContactListEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    colors: [{ name: 'green3', label: 'Green' }, ...DLR_BG_COLORS],
    defaultBGColor: 'green3',
    schemaEnhancer: composeSchema(
      defaultStylingSchema,
      contactListSchemaEnhancer,
    ),
    dataAdapter: ContactListDataAdapter,
  };

  config.blocks.blocksConfig.project = {
    id: 'project',
    title: 'Project Factsheet',
    icon: worldSVG,
    dataAdapter: ProjectFactsheetDataAdapter,
    view: ProjectFactsheetView,
    edit: ProjectFactsheetEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    color: DLR_BG_COLORS,
    defaultBGColor: 'transparent',
    schemaEnhancer: defaultStylingSchema,
  };

  config.blocks.blocksConfig.facebookBlock = {
    ...config.blocks.blocksConfig.facebookBlock,
    restricted: true,
  };

  config.blocks.blocksConfig.tweetBlock = {
    ...config.blocks.blocksConfig.tweetBlock,
    schemaEnhancer: socialBlockSchemaEnhancer,
    mostUsed: false,
    restricted: true,
  };
  config.blocks.blocksConfig.instagramBlock = {
    ...config.blocks.blocksConfig.instagramBlock,
    schemaEnhancer: socialBlockSchemaEnhancer,
    restricted: true,
  };
  config.blocks.blocksConfig.rssBlock = {
    ...config.blocks.blocksConfig.rssBlock,
    title: 'RSS',
    mostUsed: false,
    group: 'common',
    templates: {
      ...config.blocks.blocksConfig.rssBlock.templates,
      default: {
        label: 'Simplified Template',
        template: SimplifiedRssTemplate,
      },
    },
  };

  config.blocks.blocksConfig.infoboxBlock = {
    id: 'infoboxBlock',
    title: 'Infobox',
    icon: infoSVG,
    view: InfoboxView,
    edit: InfoboxEdit,
    group: 'common',
    restricted: restrictedExceptForSubsites(['/de/karriere/', '/en/careers/']),
    mostUsed: false,
    sidebarTab: 1,
    // This has a good reason: Slate does not work in detached mode if enabled
    blockHasOwnFocusManagement: true,
  };

  config.blocks.blocksConfig.search.variations = [
    {
      id: 'facetsTopSide',
      title: 'Facets on top',
      view: TopSideFacets,
      isDefault: true,
    },
  ];

  config.blocks.blocksConfig.search.schemaEnhancer = searchBlockSchemaEnhancer;

  config.blocks.blocksConfig.search.extensions.facetWidgets.rewriteOptions = (
    name,
    choices,
  ) => {
    switch (name) {
      case 'review_state':
        return choices.map((opt) => ({
          ...opt,
          label: opt.label.replace(/\[.+\]/, '').trim(),
        }));
      case 'year':
        return choices.reverse();
      default:
        return choices;
    }
  };

  config.blocks.blocksConfig.search.extensions.facetWidgets.types = [
    ...config.blocks.blocksConfig.search.extensions.facetWidgets.types,
    {
      id: 'booleanSelectFacet',
      title: 'Boolean Select',
      view: BooleanSelectFacet,
      isDefault: false,
      schemaEnhancer: BooleanSelectFacet.schemaEnhancer,
      stateToValue: BooleanSelectFacet.stateToValue,
      valueToQuery: BooleanSelectFacet.valueToQuery,
      filterListComponent: SelectFacetFilterListEntry,
    },
  ];

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    icon: listingSVG,
    schemaEnhancer: composeSchema(defaultStylingSchema, listingSchemaEnhancer),
    colors: DLR_BG_COLORS,
    allowed_headline_tags: [['h2', 'h2']],
    variations: [
      {
        id: 'default',
        title: 'Standard',
        isDefault: true,
        template: DefaultListingBlockTemplate,
      },
      {
        id: 'summary',
        title: 'Standard with image',
        template: SummaryListingBlockTemplate,
      },
      {
        id: 'blogListing',
        title: 'Blog Listing',
        template: BlogListingTemplate,
      },
      {
        id: 'newsListing',
        title: 'News',
        template: NewsListingTemplate,
      },
      {
        id: 'newsImageListing',
        title: 'News with image',
        template: NewsImageListingTemplate,
      },
      {
        id: 'eventListing',
        title: 'Events',
        template: EventListingTemplate,
      },
      {
        id: 'eventImageListing',
        title: 'Events with image',
        template: EventImageListingTemplate,
      },
      // {
      //   id: 'imageListing',
      //   title: 'Image gallery',
      //   template: ImageListingTemplate,
      // },
      {
        id: 'imageGallery',
        title: 'Image slider',
        template: ImageGalleryListingBlockTemplate,
      },
      {
        id: 'fileImageListing',
        title: 'File with image',
        template: FileImageListingTemplate,
      },
      {
        id: 'NewsGridListing',
        title: 'News as Grid',
        template: NewsGridListingTemplate,
      },
    ],
  };

  config.settings.slate.toolbarButtons = [
    'bold',
    'italic',
    'strikethrough',
    'link',
    'separator',
    'heading-two',
    'heading-three',
    'separator',
    'sub',
    'sup',
    'separator',
    'numbered-list',
    'bulleted-list',
    'separator',
    'clearformatting',
  ];

  config.settings.slate.scrollIntoView = false;

  config.settings.slate.textblockDetachedKeyboardHandlers = {
    ...config.settings.slate.textblockDetachedKeyboardHandlers,
    Backspace: [
      backspaceInList, // Backspace in list item lifts node and breaks Volto blocks
    ],
  };

  delete config.views.layoutViews.link_redirect_view;
  config.views.contentTypesViews.Audio = AudioView;
  config.views.contentTypesViews.Contact = ContactView;
  config.views.contentTypesViews.Event = EventView;
  config.views.contentTypesViews.Institute = ConditionalRedirectView;
  config.views.contentTypesViews.Link = LinkView;
  config.views.contentTypesViews['Research Project'] = ConditionalRedirectView;
  config.views.contentTypesViews.Video = VideoView;
  config.views.contentTypesViews.Author = AuthorProfileView;
  config.views.contentTypesViews.Post = PostView;
  config.views.contentTypesViews.BlogTag = TagView;
  config.views.contentTypesViews.Newsletter = NewsletterSubscribeForm;
  config.views.contentTypesViews.Longpager = LongpagerView;

  // VERY IMPORTANT notice. The rexexps must NOT consume the beginning of the path,
  // that is, NEVER use /.*\/whatever-action$/ as a regexp, unless the action is executed
  // on the portal root as a context. - Reason: getBaseUrl will
  // replace anything that the regexp matches with an empty string, and the desired
  // result, most of the time, is the actual content path, not the portal root.
  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes,
    /\/@@search$/,
    '/rss',
    /.*\/translation-not-found/,
    '/schoollab/booking-form',
    '/contact-form',
    '/kontakt-formular',
    '/*/kontakt-formular',
    '/*/contact-form',
    '/magazine-subscription',
    '/magazin-abo',
    '/newsletter-bestaetigen',
    '/newsletter-confirm',
    '/newsletter-abbestellen',
    '/newsletter-unsubscribe',
    '/newsletter-delivery',
    '/dlr/(en/)?portaldata/1/modules/newsletterunsubscribe.aspx',
  ];

  // --
  // View type mapping for the search results in support of Search.jsx
  // --

  // View mapper can be specified here. with the desired type rendererers.
  // The default view applies for all content types not in the array.
  // Or just use the built in mapper.
  config.views.contentTypeSearchResultViews = {
    'Blog Post': searchResultItems.NewsItemResultItem,
    Event: searchResultItems.EventResultItem,
    'News Item': searchResultItems.NewsItemResultItem,
    Image: searchResultItems.ImageResultItem,
  };
  config.views.contentTypeSearchResultDefaultView =
    searchResultItems.DefaultResultItem;

  // Config for Img component
  config.settings.srcSetOptions = {
    // enabled: true,
    isLocal: isInternalURL,
    preprocessSrc: (src) =>
      flattenToAppURL(
        src.replace(/\/@@images\/image.*$/, '').replace(/\/$/, ''),
      ),
    createScaledSrc: (src, scaleName, scaleData) => ({
      url: scaleData.download.startsWith('http')
        ? scaleData.download
        : `${src}/${scaleData.download}`,
      width: scaleData.width,
    }),
    createMissingScaleSrc: (src, scaleName) =>
      `${src}/@@images/image/${scaleName}`,
    minWidth: 0,
    maxWidth: Infinity,
    scales: {},
  };
  config.settings.blurhashOptions = {
    resolutionX: 32,
    punch: 1,
    style: { width: '100%', height: 'auto' },
  };
  // <Component componentName="img" {...props} />
  //
  config.registerComponent({
    name: 'Img',
    component: Img,
  });

  // remove CookieBanner from AppExtras
  config.settings.appExtras = config.settings.appExtras.filter(
    (obj) => obj?.component !== Banner,
  );

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: StagingBanner,
    },
  ];

  config.settings.openExternalLinkInNewTab = true;

  config.settings.DSGVOBanner.modules = [
    'soundcloud',
    'podcaster',
    'youtube',
    'vimeo',
    'quickchannel',
    'gmaps',
    'osm',
    'twitter',
    'instagram',
  ];
  config.settings.DSGVOBanner.privacy_url = {
    de: '/de/service/datenschutz-einstellungen',
    en: '/en/service/privacy-settings',
  };
  config.settings.DSGVOBanner.tracker = {
    type: null,
  };

  config.addonRoutes = [
    ...config.addonRoutes,
    {
      path: ['/de/rss', '/en/rss'],
      component: RssView,
    },
    {
      path: [
        '/de/translation-not-found',
        '/de/**/translation-not-found',
        '/en/translation-not-found',
        '/en/**/translation-not-found',
      ],
      component: TranslationNotFound,
    },
    {
      path: ['/de/schoollab/booking-form', '/en/schoollab/booking-form'],
      component: SchoolLabContactForm,
    },
    {
      path: ['/de/kontakt-formular', '/en/contact-form'],
      component: ContactForm,
    },
    {
      path: ['/contact-form', '/de/contact-form'],
      component: () => <Redirect to="/" />,
    },
    {
      path: ['/de/*/kontakt-formular', '/en/*/contact-form'],
      component: SubsiteContactForm,
    },
    {
      path: ['/de/magazin-abo', '/en/magazine-subscription'],
      component: SubscriberForm,
    },
    {
      path: ['/reports/editor-emails'],
      component: EditorEmails,
    },
    {
      path: ['/de/*/newsletter-bestaetigen', '/en/*/newsletter-confirm'],
      component: NewsletterConfirmationForm,
    },
    {
      path: ['/de/*/newsletter-abbestellen', '/en/*/newsletter-unsubscribe'],
      component: NewsletterUnsubscribeForm,
    },
    {
      path: ['/de/*/newsletter-delivery', '/en/*/newsletter-delivery'],
      component: NewsletterDelivery,
    },
    {
      path: [
        '/dlr/portaldata/1/modules/newsletterunsubscribe.aspx',
        '/dlr/en/portaldata/1/modules/newsletterunsubscribe.aspx',
      ],
      component: NewsletterUnsubscribeForm,
    },
  ];

  config.blocks.blocksConfig.__grid = {
    ...config.blocks.blocksConfig.__grid,
    colors: DLR_BG_COLORS,
    schemaEnhancer: defaultStylingSchema,
    icon: gridSVG,
    gridAllowedBlocks: ['teaser', 'image', 'slate'],
    // One could customize the blocks inside the grid like this:
    blocksConfig: {
      ...config.blocks.blocksConfig,
      teaser: {
        ...config.blocks.blocksConfig.teaser,
        schemaEnhancer: composeSchema(
          teaserSchemaEnhancer,
          gridTeaserSchemaEnhancer,
        ),
      },
    },
  };

  config.blocks.blocksConfig.logos.view =
    config.blocks.blocksConfig.__grid.view;
  config.blocks.blocksConfig.logos.edit =
    config.blocks.blocksConfig.__grid.edit;
  config.blocks.blocksConfig.logos.gridAllowedBlocks =
    config.blocks.blocksConfig.logos.allowedBlocks;

  // Wrapper for a customized Solr Search component that can be used
  // directly as a route. On long term these parameters could be acquired from Search.jsx and
  // this wrapper could go away.
  config.widgets.SolrSearch = (props) =>
    Search({
      ...props,
      searchAction: solrSearchContent,
      getSearchReducer: (state) => state.solrsearch,
      copyContentForSolrAction: copyContentForSolr,
      contentTypeSearchResultViews: config.views.contentTypeSearchResultViews,
      contentTypeSearchResultDefaultView:
        config.views.contentTypeSearchResultDefaultView,
    });
  config.addonReducers = { ...config.addonReducers, ...reducers };

  if (config.settings.serverConfig.extractScripts) {
    config.settings.serverConfig.extractScripts.errorPages = true;
  }

  // Vocabularies which should be fetched in the current context
  config.settings.contextualVocabularies = [
    'collective.blog.authors',
    'collective.blog.tags',
    'dlr.internet.vocabulary.internal_keywords',
    'dlr.internet.vocabulary.subsite_research_topics',
    'dlr.internet.vocabulary.subsite_projects_and_missions',
  ];
  config.settings.contentPropertiesSchemaEnhancer = contentsSchemaEnhancer;
  return config;
};

export default applyConfig;

//
