import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import EventDates from 'volto-dlr/components/Blocks/EventMetadata/EventDates';

const EventListingTemplate = ({ items, linkMore, isEditMode }) => {
  let link = null;
  let href = linkMore?.href || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink
        to={flattenToAppURL(href)}
        condition={!isEditMode}
        className="ui button"
      >
        {linkMore?.title || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkMore?.title || href}</a>;
  }

  return (
    <>
      <div className="news-listing-template">
        <div className="items">
          {items.map((item) => (
            <div key={item['@id']} className="event-item">
              <div className="content">
                <div className="listing-item" key={item['@id']}>
                  <div className="text">
                    <ConditionalLink
                      to={flattenToAppURL(item['@id'])}
                      condition={!isEditMode}
                    >
                      <div className="listing-body">
                        <div className="head-title">
                          {item?.['@type'] === 'Event' && (
                            <EventDates content={item} showTime={false} />
                          )}
                        </div>
                        <h3>{item.title ? item.title : item.id}</h3>
                        <p className="description">{item?.description}</p>
                      </div>
                    </ConditionalLink>
                    {item?.subjects && item.subjects?.length >= 1 ? (
                      <div className="tags">
                        {item.subjects.map((item, i) => {
                          return (
                            <ConditionalLink
                              key={item}
                              to={`/search?Subject=${item}`}
                              condition={!isEditMode}
                            >
                              {item}
                            </ConditionalLink>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {link && <div className="block-footer">{link}</div>}
      </div>
    </>
  );
};
EventListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default EventListingTemplate;
